<template>
  <div class="content-box">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="脉诊设备管理" name="first">
        <QiaoDeviceList />
      </el-tab-pane>
      <el-tab-pane label="脉诊充值明细" name="second">
        <QiaoDeviceDetail />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Watch, Mixins, Component, ProvideReactive } from "vue-property-decorator";
import QiaoDeviceList from "./components/deviceList.vue";
import QiaoDeviceDetail from "./components/detail.vue";

@Component({
  components: { QiaoDeviceList, QiaoDeviceDetail },
  inject: [],
})
export default class QiaoDevice extends Mixins() {
  @ProvideReactive() activeName;
  activeName = "first";
}
</script>



<style lang="scss" scoped src="./style.scss">
</style>
<style lang="scss" scoped>
.content-box {
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff;
}
:deep(.el-tabs) {
  margin-top: 20px;
}
:deep(.el-tabs__header) {
  // border-top: 1px solid #E4E7ED;
}
.renew-status-2{
  color: red;
}
.el-icon-warning-outline{
  color: #666666;
  &:empty::after{
    content: "";
  }
}
</style>